import { useState } from 'react'
import emailjs from 'emailjs-com'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Slot } from './slot';

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const [selected, setSelected] = useState(new Date())

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  const getSlots = () => {
    const slots = ["10:00 AM - 11:00 AM", "11:00 AM - 12:00 AM", "12:00 AM - 01:00 PM", "05:00 PM - 06:00 PM", "06:00 PM - 07:00 PM", "07:00 PM - 08:00 PM"]
    return slots.reduce((prev, value) => {
      return {...prev, ...{[value] : Math.random() * 100 > 60}}
    }, {})
  }

  const fillSlots = () => {
    const slots = getSlots()
    console.log(JSON.stringify(slots))
    return Object.keys(slots).map(slot => {
      return <Slot text={slot} disabled={slots[slot]} key={slot}/>
    })
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>
                  For Appointments
                  <br/><br/>
                  <i className='fa fa-phone'></i>{' '}<a style={{color:'white'}} href="tel:+919000381535">+91 90003 81535</a>
                  <br/><br/>
                  <i className='fa fa-phone'></i>{' '}<a style={{color:'white'}} href="tel:+914048522160">+91 40485 22160</a>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
            <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15224.955881446205!2d78.44977!3d17.4482727!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3502c0ea61ad6274!2sNakshathra%20Multi-Speciality%20Dental%20Clinic!5e0!3m2!1sen!2sin!4v1674151515026!5m2!1sen!2sin" width="300" height="225" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 Nakshathra Multispeciality Dental Clinic {' '}
          </p>
        </div>
      </div>
    </div>
  )
}
